<template>
  <div v-if="slide">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('slide.create')"
      :title="$t('slide.create')"
      @submitPressed="submitPressed"
    />
    <slide-form
      :slide-obj="slide"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import SlideForm from "@/views/Admin/Banners/components/SlideForm.vue";

export default {
  components: {
    TitleBar,
    SlideForm,
  },
  data() {
    return {
      action: null,
      slide: {
          name: '',
          description: '',
          file: null,
      },
    };
  },
  methods: {
    submitPressed() {
      this.action = "add";
    },
  },
};
</script>
