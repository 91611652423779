<template>
  <div v-if="slide">
    <b-form @submit.prevent="onSubmit">
      <b-form-group class="mb-0">
        <b-card>
          <b-form-group :class="{ row, error: v$.slide.name.$errors.length }" :label="$t('slide.name')">
            <b-form-input v-model="slide.name" />
            <div
              class="input-errors"
              v-for="error of v$.slide.name.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <tiny-mce
            :options="{ height: 200 }"
            :content="slide.description"
            :parent-object="slide"
            @editorContentChanged="updateContent"
          />
          <image-upload
            class="mt-3"
            :for-slide="true"
            :resource="slide['@id']"
            v-if="slide['@id']"
            file-attribute="FILE"
            :preview-image="slide.fileUrl"
            :label="$t('slide.imageAdd')"
            :parent-object="slide"
            @fileUploaded="ilustrationImageChange"
          />
        </b-card>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import tinyMce from "@/components/tinymce.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  props: {
    slideObj: { type: Object, default() {} },
    action: { type: String, default: null },
  },
  components: {
    ImageUpload,
    tinyMce,
  },
  data() {
    return {
      slide: this.slideObj,
    };
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      slide: {
        name: { required: this.$translateError('required') },
      },
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    updateContent(data) {
      data.parent.description = data.content;
    },
    ilustrationImageChange({ parent, data }) {
      parent.file = data["@id"];
      parent.fileURL = data.url;
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();

      if (isValid) {
        const body = {
          name: this.slide.name,
          description: this.slide.description,
          onOff: this.slide.onOff,
          file: this.slide.file,
        };

        if (this.action) {
          this.action === "add"
            ? this.create(
                this.$BannerSlides,
                body,
                this.$t("slide.created"),
                null,
                this.success,
                this.error
              )
            : this.update(
                this.$BannerSlides,
                this.slide.id,
                body,
                this.$t("slide.updated"),
                null,
                null,
                this.error
              );
          this.$emit("clearAction");
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/banners`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

